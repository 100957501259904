
// ==============================================

//        CONTACT

// ==============================================


.contact {
  position: relative;
  color: $color-navy;
  background-image: linear-gradient(to bottom, $color-light-gray, #FFFFFF);

  .wrapper {
    max-width: 770px;
    padding: 100px 2rem;
    display: flex;
    align-items: flex-start;
    // @include bd();
  }
}

.contact-image {
  margin-right: 75px;
}

.contact-infos {
  flex: 1;

  h2 {
    margin: 5px 0;
    font-size: 28px;
  }

  .contact-infos__text {
    margin: 0 0 26px;
    font-size: 17px;
  }

  .contact-infos__name {
    margin: 0 0 5px;
    font-size: 18px;
    font-weight: $weight-bold;
  }

  .contact-infos__tel {
    margin: 0;
    font-size: 18px;
  }

  hr {
    height: 1px;
    width: 100%;
    margin: 0 0 10px;
    border: none;
    background-color: rgba($color-navy, 0.1);
  }

  a {
    display: inline-block;
    margin-bottom: 6px;
    font-size: 21px;
    font-weight: $weight-bold;
    color: $color-orange;

    &:hover {
      text-decoration: underline;
    }
  }
}


.--uppercase { text-transform: uppercase;}

//  Styles for GD version
// -----------------------------------------------

.contact.contact--gd {
  .wrapper {
    max-width: 1220px;
    &.--small { 
      max-width: 720px;
      padding-bottom: 0;
    }
  }

  .outlined {
    // max-width: 1020px;
    border: 2px solid #9d824f;
    padding: 3rem ;
    display: flex;
    flex-direction: column;

    .cols {
      display: flex;
      
      & > *:nth-child(1) { 
        flex: 0 0 50%; 
      }
      & > *:nth-child(2) { 
        padding: 0 1rem;
        flex: 0 0 25%; 
        a { font-size: 1rem; }
      }
      & > *:nth-child(3) { 
        flex: 0 0 25%; 
        padding-top: 1rem;
      }
           
    }

    @include breakpoint(medium down) {
      padding: 2rem 1rem;
      .cols {
        flex-direction: column;
        & > * { 
          padding: 0 !important;
          flex: 0 0 100%;
        }
      }
    }

  }

  
  .contact-image {
    margin-right: 60px;
  }

  .contact-logo-cercle {
    align-self: center;
    width: 220px;
  }

  .contact-infos {

    hr {
      margin-left: 0;
      width: calc(100% - 70px);
      // width: calc(100% + 200px);
    }
  }

  @include breakpoint(medium down) {
    .wrapper {
      padding: 60px 1rem;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .contact-image {
      margin-right: 0;
      margin-bottom: 3rem;
    }
    
    .contact-infos hr {
      width: 100%;
    }
  }
}



//  MQ Breakpoints
// -----------------------------------------------

@include breakpoint(770px down) {
  .contact-image {
    margin-right: 40px;
  }
}

@include breakpoint(small only) {
  .contact .wrapper {
    padding: 60px 1rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .contact-image {
    display: none;
  }
}
